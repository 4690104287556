import { ApiCallOptions } from "@/new-services/types";

export const SUCCESS_MESSAGE = {
  IMAGES_DOWNLOADED: "Images downloaded successfully",
  IMAGES_UPLOADED: "Images uploaded successfully",
  CANDIDATE_VERIFIED: "Candidate Verified",
};

export const ERROR_MESSAGE = {
  SOMETHING_WENT_WRONG: "Something went wrong",
  UNAUTHORIZED: "Unauthorized. Please log in again.",
  FAILED_TO_CAPTURE_IMAGE: "Failed to capture image",
  FAILED_TO_ACCESS_CAMERA: "Can't access the camera",
  BARCODE_NOT_FOUND: "No barcode text detected",
  INVALID_SCAN_CONTEXT: "useScanning must be used within a ScanProvider",
  INVALID_REPORT_CONTEXT:
    "useReportContext must be used within a ReportProvider",
  INVALID_BREADCRUMB_CONTEXT:
    "useBreadCrumbContext must be used within a BreadCrumbContextProvider",
  NO_DATA: "No data available",
  INVALID_CANDIDATE_DETAILS: "Invalid candidate details",
  DOWNLOAD_FAILED: "Failed to download the file",
  SCANNER_CREATE_IMAGE_FAILED: "Please upload the image again",
};

export const DEFAULT_API_OPTIONS: ApiCallOptions = {
  isMultiPart: false,
  responseType: "json",
};
