"use client";

import { ERROR_MESSAGE } from "@/constants/messages";
import { createContext, ReactNode, useContext, useState } from "react";

type TypeBreadCrumbValue = { id: string; value: string };

type TypeBreadCrumb = {
  client: TypeBreadCrumbValue;
  exam: TypeBreadCrumbValue;
  job: TypeBreadCrumbValue;
  scanner: TypeBreadCrumbValue;
  evaluator: TypeBreadCrumbValue;
};

type TypeBreadCrumbContext = {
  breadCrumb: TypeBreadCrumb;
  handleBreadCrumb: ({
    key,
    value,
  }: {
    key: keyof TypeBreadCrumb;
    value: TypeBreadCrumbValue;
  }) => void;
};

const DEFAULT_VALUE = { id: "", value: "" };

const BreadCrumbContext = createContext<TypeBreadCrumbContext | undefined>(
  undefined
);

export const BreadCrumbContextProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [breadCrumb, setBreadCrumb] = useState<TypeBreadCrumb>({
    client: DEFAULT_VALUE,
    evaluator: DEFAULT_VALUE,
    exam: DEFAULT_VALUE,
    job: DEFAULT_VALUE,
    scanner: DEFAULT_VALUE,
  });

  const handleBreadCrumb = ({
    key,
    value,
  }: {
    key: keyof typeof breadCrumb;
    value: TypeBreadCrumbValue;
  }) => {
    setBreadCrumb((prev) => {
      if (prev[key].id !== value.id) return { ...prev, [key]: value };
      return prev;
    });
  };

  return (
    <BreadCrumbContext.Provider value={{ breadCrumb, handleBreadCrumb }}>
      {children}
    </BreadCrumbContext.Provider>
  );
};

export const useBreadCrumbContext = () => {
  const context = useContext(BreadCrumbContext);

  if (!context) {
    throw new Error(ERROR_MESSAGE.INVALID_BREADCRUMB_CONTEXT);
  }
  return context;
};
